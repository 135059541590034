import styled, { css } from 'styled-components'
import { PaddingType } from 'common/types/styleTypes'
import {
  fontCss,
  FontProps,
  mobilePaddingsCss,
  paddingsCss,
} from 'common/utils/styleUtilsNew'

interface BookingCalendarContainerUiProps {
  padding: PaddingType
  mobilePadding: PaddingType
}

export const CommonCalendarUi = styled.div<
  BookingCalendarContainerUiProps & FontProps
>`
  width: fit-content;
  height: 100%;
  background-color: white;

  display: flex;
  border-radius: 6px;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 20px;

  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  ${paddingsCss}
  ${fontCss}

  ${p =>
    p.theme.isEditorMobileMode &&
    css`
      ${mobilePaddingsCss}
      flex-direction: column;
    `}
  ${p => p.theme.phone} {
    ${mobilePaddingsCss}
    flex-direction: column;
  }
`
