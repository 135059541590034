import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import Alert from 'common/components/core/Alert'
import { pageTypes } from 'common/constants/pageTypes'
import useManagement, {
  selectors as managementSelectors,
} from '../../hooks/useManagement'
import usePage, { selectors as pageSelectors } from '../../hooks/usePage'

function PreviewBanner() {
  const isPreview = useManagement(managementSelectors.isPreviewMode)
  const pageType = usePage(pageSelectors.getPageType)
  const { t } = useTranslation(['publisher'])
  const [labelText, setLabelText] = useState('')
  const isPreviewAndNotRemoteFormAndPopup =
    isPreview && pageType !== pageTypes.inline && pageType !== pageTypes.popup

  useEffect(() => {
    setLabelText(t('components.preview.warning'))
  }, [])

  return isPreviewAndNotRemoteFormAndPopup ? (
    <Alert type="warning">{labelText}</Alert>
  ) : null
}

export default PreviewBanner
