import {
  STEP_OPT_IN,
  STEP_PAYMENT,
} from 'common/components/entities/TwoStepPaymentForm'
import { customerTypes } from 'common/constants/customTypes'
import {
  CHANGE_COUNTRY_VAT,
  TOGGLE_CHECKED_BUMP,
  TOGGLE_CUSTOMER_TYPE,
  TOGGLE_PAYMENT_METHOD,
  TOGGLE_OFFER_PRICE_PLAN,
  TOGGLE_BUMP_PRICE_PLAN,
  UPDATE_COUPON,
  CHECK_COUPON,
} from 'publisher/actionTypes'
import pricePlanTypes from 'publisher/constants/pricePlanTypes'
import {
  SET_ACTIVE_PRICE_PLAN_ID,
  SET_ACTIVE_PRODUCT_VARIANT,
  SET_DLOCAL_DOCUMENT_NUMBER,
  SET_OFFER_PRICING,
  SET_SELECTED_SHIPPING,
} from 'publisher/store/payment/paymentActionTypes'
import { SHOW_TWO_STEP_PAYMENT_FORM_STEP_PAYMENT } from '../actionTypes'
import {
  INCREASE_PRODUCT_QUANTITY,
  DECREASE_PRODUCT_QUANTITY,
  INITIALIZE_PRODUCT_QUANTITY,
} from '../store/payment/paymentActionTypes'

const paymentMethodsPlaceholder = '%PAYMENT_METHODS%'
const isVatNotChargeablePlaceholder = '%IS_ONLY_PERSONAL_AVAILABLE%'
const offerPlaceholder = '%OFFER%'
const customerPaymentMethodPlaceholder = '%CUSTOMER_PAYMENT_METHOD%'
const offerPricePlanActivePlaceholder = '%OFFER_PRICE_PLAN_ACTIVE%'
const offerOwnerCountryPlaceholder = '%OFFER_OWNER_COUNTRY%'
const offerBumpsPlaceholder = '%OFFER_BUMPS%'
const offerBumpPlaceholder = '%OFFER_BUMP%'
const agreementPlaceholder = '%AGREEMENT%'
export const customerTypePlaceholder = '%CUSTOMER_TYPE%'
const customerEmailPlaceholder = '%CUSTOMER_EMAIL%'
const pageIdPlaceholder = '%PAGE_ID%'
const affiliateLinkPlaceholder = '%AFFILIATE_LINK%'
const stripeAccountIdPlaceholder = '%STRIPE_ACCOUNT_ID%'
const purchaseProcessIdPlaceholder = '%PURCHASE_PROCESS_ID%'
const deadlineTimestampPlaceholder = '%DEADLINE_TIMESTAMP%'
const deadlineExpirationUrlPlaceholder = '%DEADLINE_EXPIRATION_URL%'
const downsellUrlPlaceholder = '%DOWNSELL_URL%'
const mercadoPagoPublicKeyPlaceholder = '%MERCADO_PAGO_PUBLIC_KEY%'
export const cashOnDeliveryInstructionPlaceholder =
  '%CASH_ON_DELIVERY_INSTRUCTION%'
const xenditPublicKeyPlaceholder = '%XENDIT_PUBLIC_KEY%'

const productPlaceholder = '%PRODUCT%'

const defaultState = {
  customerType: customerTypePlaceholder,
  customerEmail: customerEmailPlaceholder,
  paymentMethods: paymentMethodsPlaceholder,
  isOnlyPersonalAvailable: isVatNotChargeablePlaceholder,
  offer: offerPlaceholder,
  checkedPlanId: offerPricePlanActivePlaceholder,
  customerPaymentMethod: customerPaymentMethodPlaceholder,
  offerOwnerCountry: offerOwnerCountryPlaceholder,
  offerBumps: offerBumpsPlaceholder,
  offerBump: offerBumpPlaceholder,
  offerPricing: [],
  checkedBumpsIds: [],
  checkedBumpId: undefined,
  agreement: agreementPlaceholder,
  couponCode: '',
  checkedCoupon: null,
  pageId: pageIdPlaceholder,
  affiliateLink: affiliateLinkPlaceholder,
  stripeAccountId: stripeAccountIdPlaceholder,
  purchaseProcessId: purchaseProcessIdPlaceholder,
  twoStepPaymentFormStepType: STEP_OPT_IN,
  deadlineTime: deadlineTimestampPlaceholder,
  deadlineExpirationUrl: deadlineExpirationUrlPlaceholder,
  dlocalDocumentNumber: undefined,
  downsellUrl: downsellUrlPlaceholder,
  mercadoPagoPublicKey: mercadoPagoPublicKeyPlaceholder,
  product: productPlaceholder,
  productQuantity: 1,
  shipping: null,
  productActiveVariant: null,
  cashOnDeliveryInstruction: cashOnDeliveryInstructionPlaceholder,
  xenditPublicKey: xenditPublicKeyPlaceholder,
}

const updateBumps = (bumps, newBump) => {
  const foundBump = bumps.find(bump => bump === newBump)
  if (foundBump) {
    return bumps.filter(bump => bump !== newBump)
  }

  return [...bumps, newBump]
}

export default function (state = defaultState, action) {
  const { type, payload } = action
  switch (type) {
    case TOGGLE_CUSTOMER_TYPE:
      return {
        ...state,
        customerType: payload,
      }
    case TOGGLE_OFFER_PRICE_PLAN:
    case SET_ACTIVE_PRICE_PLAN_ID:
      return {
        ...state,
        checkedPlanId: payload,
      }
    case TOGGLE_BUMP_PRICE_PLAN:
      return {
        ...state,
        checkedBumpPricePlanId: payload,
      }
    case CHANGE_COUNTRY_VAT:
      return {
        ...state,
        vat: payload,
      }
    case TOGGLE_PAYMENT_METHOD:
      return {
        ...state,
        customerPaymentMethod: payload,
      }
    case TOGGLE_CHECKED_BUMP:
      return {
        ...state,
        checkedBumpsIds: updateBumps(state.checkedBumpsIds, payload),
        checkedBumpId: state.checkedBumpId === payload ? null : payload,
      }
    case INCREASE_PRODUCT_QUANTITY:
      return {
        ...state,
        productQuantity: state.productQuantity + 1,
      }
    case DECREASE_PRODUCT_QUANTITY:
      if (state.productQuantity === 1) {
        return state
      }

      return {
        ...state,
        productQuantity: state.productQuantity - 1,
      }

    case SET_ACTIVE_PRODUCT_VARIANT:
      return {
        ...state,
        productActiveVariant: payload,
      }
    case INITIALIZE_PRODUCT_QUANTITY:
      return {
        ...state,
        productQuantity: payload,
      }
    case UPDATE_COUPON:
      return {
        ...state,
        couponCode: payload,
      }
    case CHECK_COUPON + 'SUCCESS':
      return {
        ...state,
        checkedCoupon: payload,
        couponError: '',
      }
    case CHECK_COUPON + 'FAIL':
      return {
        ...state,
        couponError: payload,
        checkedCoupon: null,
      }
    case SHOW_TWO_STEP_PAYMENT_FORM_STEP_PAYMENT:
      return {
        ...state,
        twoStepPaymentFormStepType: STEP_PAYMENT,
      }
    case SET_DLOCAL_DOCUMENT_NUMBER:
      return { ...state, dlocalDocumentNumber: payload }
    case SET_SELECTED_SHIPPING:
      return { ...state, shipping: payload }
    case SET_OFFER_PRICING:
      return {
        ...state,
        offerPricing: payload,
      }
    default:
      return state
  }
}

export const getCustomerType = ({ customerType }) => {
  if (customerType === customerTypePlaceholder) {
    return undefined
  }

  return customerType
}

export const isCustomerPersonal = ({ customerType }) =>
  customerType === customerTypes.PERSONAL

export const getPaymentMethods = ({ paymentMethods }) => {
  try {
    return JSON.parse(paymentMethods)
  } catch (e) {
    return []
  }
}

export const isPaymentMethodsPlaceholder = ({ paymentMethods }) =>
  paymentMethods === paymentMethodsPlaceholder

export const isVatNotChargeable = ({ isOnlyPersonalAvailable }) =>
  !!isOnlyPersonalAvailable

export const getPricePlans = ({ offer }) => {
  try {
    const parsedOffer = JSON.parse(offer)
    return parsedOffer.pricePlans
  } catch (e) {
    return []
  }
}

export const getOffer = ({ offer }) => {
  try {
    return JSON.parse(offer)
  } catch (e) {
    return null
  }
}

export const getProduct = ({ offer }) => {
  try {
    const parsedOffer = JSON.parse(offer)
    return parsedOffer.product
  } catch (e) {
    return null
  }
}

export const getPurchaseProcessId = ({ purchaseProcessId }) => purchaseProcessId

export const getCheckedOfferPricePlanId = ({ checkedPlanId }) =>
  Number(checkedPlanId)

export const getCheckedPricePlan = state => {
  return getPricePlans(state).find(plan => plan.id === state.checkedPlanId)
}

export const getCountryVat = ({ vat }) => vat

export const getActivePaymentMethod = state => {
  return state.customerPaymentMethod
    ? state.customerPaymentMethod
    : getPaymentMethods(state)[0]
}

export const getOfferOwnerCountry = ({ offerOwnerCountry }) => offerOwnerCountry

export const getOfferBumps = ({ offerBumps }) => {
  try {
    return JSON.parse(offerBumps)
  } catch (e) {
    return []
  }
}

export const getCheckedBumpsIds = ({ checkedBumpsIds }) => checkedBumpsIds

export const getCheckedBumps = ({ checkedBumpsIds, offerBumps }) => {
  let bumps
  try {
    bumps = JSON.parse(offerBumps)
  } catch (e) {
    bumps = []
  }

  return bumps.filter(bump => checkedBumpsIds.includes(bump.id))
}

export const getAgreement = ({ agreement }) => agreement

export const getCouponCode = ({ couponCode }) => couponCode

export const getCheckedCoupon = ({ checkedCoupon }) => checkedCoupon

export const getCouponError = ({ couponError }) => couponError

export const getPageId = ({ pageId }) => pageId

export const getAffiliateLink = ({ affiliateLink }) => affiliateLink

export const getActivePricePlanType = state => {
  const pricePlans = getPricePlans(state)
  const activePricePlan = pricePlans.find(
    plan => plan.id === Number(state.checkedPlanId),
  )

  if (!activePricePlan) {
    return null
  }

  if (!activePricePlan.subscriptionPlan) {
    return pricePlanTypes.oneShot
  } else if (activePricePlan.subscriptionPlan.trialPeriod) {
    return pricePlanTypes.subscriptionWithTrial
  }

  return pricePlanTypes.subscriptionWithoutTrial
}

export const getCheckedBumpPricePlanType = state => {
  const checkedBumpPlan = getCheckedBumps(state)[0]
  if (!checkedBumpPlan) {
    return null
  }

  if (!checkedBumpPlan.subscriptionPlan) {
    return pricePlanTypes.oneShot
  } else if (checkedBumpPlan.subscriptionPlan.trialPeriod) {
    return pricePlanTypes.subscriptionWithTrial
  }

  return pricePlanTypes.subscriptionWithoutTrial
}

export const getStripeAccountId = ({ stripeAccountId }) => stripeAccountId

export const getTwoStepPaymentFormStepType = ({ twoStepPaymentFormStepType }) =>
  twoStepPaymentFormStepType

export const getDeadlineTime = ({ deadlineTime }) => {
  if (deadlineTime === deadlineTimestampPlaceholder) {
    return null
  }

  return parseInt(deadlineTime) * 1000
}

export const getDeadlineExpirationUrl = ({ deadlineExpirationUrl }) => {
  if (deadlineExpirationUrl === deadlineExpirationUrlPlaceholder) {
    return null
  }

  return deadlineExpirationUrl
}

export const getDownsellUrl = ({ downsellUrl }) => downsellUrl

export const selectors = {
  getTwoStepPaymentFormStepType,
  getActivePaymentMethod,
  getPaymentMethods,
  getPricePlans,
  getActivePricePlanType,
  getPurchaseProcessId,
  getCheckedOfferPricePlanId,
  getCheckedBumpsIds,
  getCheckedCoupon,
  isCustomerPersonal,
  getStripeAccountId,
  getCheckedBumpPricePlanType,
  getDeadlineTime,
  getDeadlineExpirationUrl,
  getDownsellUrl,
}
