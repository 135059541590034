import PropTypes from 'prop-types'
import { Provider as RollbarProvider } from '@rollbar/react'
import React, { createContext } from 'react'
import { Provider } from 'react-redux'
import Page from 'publisher/components/Page'
import { CreateEntityElementProvider } from 'publisher/context/create-entity-element-context'

export const CreateEntitiesContext = createContext(null)
export const rollbarConfig = {
  enabled: process.env.NODE_ENV !== 'development',
  accessToken: process.env.ROLLBAR_PUBLISHER_KEY,
  captureUncaught: false,
  captureUnhandledRejections: false,
  autoInstrument: false,
}

const App = ({ store, createEntityElement, createPage }) => {
  return (
    <CreateEntityElementProvider createEntityElement={createEntityElement}>
      <RollbarProvider config={rollbarConfig}>
        <Provider store={store}>
          <Page createPage={createPage} />
        </Provider>
      </RollbarProvider>
    </CreateEntityElementProvider>
  )
}

App.propTypes = {
  store: PropTypes.objectOf(PropTypes.any).isRequired,
  createPage: PropTypes.func.isRequired,
  createEntityElement: PropTypes.func.isRequired,
}

export default App
