import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import CommonImage from 'common/components/entities/Image'
import StyleWrapper from 'common/components/StyleWrapper'
import * as types from 'common/types'
import { pageTypes } from '../../common/constants/pageTypes'
import { toggleActivePopup } from '../actionsManagement'
import { getFile } from '../reducers/filesReducer'
import { getPageType } from '../reducers/pageReducer'
import provideDelay from '../hoc/provideDelay'
import { isScreenshotMode } from '../reducers/managementReducer'

class ImageContainer extends PureComponent {
  render() {
    const {
      file,
      entity: { options, styles, mobileStyles },
      isScreenshotMode,
      pageType,
    } = this.props
    const {
      marginTop,
      marginBottom,
      marginLeft,
      marginRight,
      justifyContent,
      ...restStyles
    } = styles
    const {
      marginTop: mobileMarginTop,
      marginBottom: mobileMarginBottom,
      marginLeft: mobileMarginLeft,
      marginRight: mobileMarginRight,
      ...restMobileStyles
    } = mobileStyles
    const { appearance = {}, popup, link } = options
    const { desktop = true, mobile = true } = appearance

    let path = (file && file.path) || styles.backgroundImage

    if (!path) {
      if (isScreenshotMode) {
        path = `${process.env.CLOUDFRONT_PUBLISHER_ASSETS_HOST}/assets/images/image_placeholder.png`
      } else {
        return false
      }
    }

    const showPopup = () => {
      if (popup) {
        this.props.toggleActivePopup(popup)
      }
    }

    return (
      <StyleWrapper
        styles={{
          position: 'relative',
          marginTop,
          marginBottom,
          marginLeft,
          marginRight,
          justifyContent,
          display: 'flex',
        }}
        mobileStyles={{
          marginTop: mobileMarginTop,
          marginBottom: mobileMarginBottom,
          marginLeft: mobileMarginLeft,
          marginRight: mobileMarginRight,
          justifyContent: restMobileStyles.justifyContent || justifyContent,
        }}
        desktop={desktop}
        mobile={mobile}
      >
        <CommonImage
          src={path}
          loading={
            pageType === pageTypes.popup || pageType === pageTypes.inline
              ? 'eager'
              : 'lazy'
          }
          options={options}
          styles={{
            ...restStyles,
            cursor: popup || link ? 'pointer' : 'default',
          }}
          showPopup={showPopup}
          mobileStyles={{
            ...restMobileStyles,
            justifyContent: restMobileStyles.justifyContent || justifyContent,
            // image_wrapper width could be stretched
            // use desktop justify-content if there is no such
          }}
        />
      </StyleWrapper>
    )
  }
}

ImageContainer.propTypes = {
  file: PropTypes.objectOf(PropTypes.any),
  entity: types.entity.isRequired,
  toggleActivePopup: PropTypes.func.isRequired,
  isScreenshotMode: PropTypes.bool.isRequired,
}

ImageContainer.defaultProps = {
  file: null,
}

const mapStateToProps = (
  { page, files, management },
  { entity: { options } },
) => ({
  file: getFile(files, options.srcFileId),
  pageType: getPageType(page),
  isScreenshotMode: isScreenshotMode(management),
})

const mapDispatchToProps = { toggleActivePopup }

export default provideDelay(
  connect(mapStateToProps, mapDispatchToProps)(ImageContainer),
)
