import styled from 'styled-components'

const Inline = styled.section`
  margin: auto;
  ${p => p.theme.phone} {
    ${({ mobileStyles = {} }) => mobileStyles};
    width: 100%;
  }
  width: 100%;
  ${({ styles = {} }) => styles}
`

export default Inline
