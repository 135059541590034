import styled, { css } from 'styled-components'

export const CalendarWrapperUi = styled.div<{ isDateSelected: boolean }>`
  display: flex;
  flex-direction: column;
  width: 560px;
  ${p => p.theme.isEditorMobileMode && css`
    width: 100%;
  `}
  ${p => p.theme.phone} {
    width: 100%;
  }
`
