import PropTypes from 'prop-types'
import React from 'react'
import CommonInline from 'common/components/entities/Inline'
import { wrapFontFamily } from 'common/components/entities/Text/utils'
import * as types from '../../../common/types'
import BaseNodeChildren from '../ChildrenEntities'

const Inline = ({ entity, globalSettings }) => {
  const { mobileStyles, options, childIds } = entity
  const styles =
    globalSettings && globalSettings.fontFamily
      ? {
          ...entity.styles,
          fontFamily: wrapFontFamily(globalSettings.fontFamily),
        }
      : entity.styles

  return (
    <CommonInline styles={styles} mobileStyles={mobileStyles} options={options}>
      <BaseNodeChildren childIds={childIds} />
    </CommonInline>
  )
}

Inline.propTypes = {
  entity: types.entity.isRequired,
  globalSettings: PropTypes.objectOf(PropTypes.any).isRequired,
}

export default Inline
