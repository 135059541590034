import React from 'react'
import StyleWrapper from 'common/components/StyleWrapper'
import CommonField from 'common/components/entities/Field/FieldUi'
import { FieldSlugEnum } from 'common/enums/FieldSlugEnum'
import { FieldInterface } from 'common/types/entities/FieldInterface'
import FieldErrors from '../../../components/FieldErrors'
import {
  getContactUsError,
  useShowContactUsSuccess,
  ContextFieldsInterface,
} from './ContactUs'

function ContactUsField({ entity }: { entity: FieldInterface }) {
  const { errors, setFields, fields } = useShowContactUsSuccess()
  const contactUsFieldError = getContactUsError(errors, entity.slug as string)
  let value = entity.slug
    ? fields[entity.slug as keyof ContextFieldsInterface]
    : ''

  if (typeof value !== 'string') {
    value = ''
  }

  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    const { value } = e.target

    if (entity.slug) {
      setFields((prev: ContextFieldsInterface) => {
        return {
          ...prev,
          [entity.slug as FieldSlugEnum]: value,
        }
      })
    }
  }

  return (
    <StyleWrapper
      styles={entity.margin}
      mobileStyles={entity.mobileMargin}
      desktop={entity.appearance.desktop}
      mobile={entity.appearance.mobile}
    >
      <CommonField
        onChange={handleChange}
        placeholder={entity.placeholder}
        padding={entity.padding}
        mobilePadding={entity.mobilePadding}
        id={entity.htmlAttrId}
        border={entity.border}
        mobileBorder={entity.mobileBorder}
        backgroundColor={entity.backgroundColor}
        mobileBackgroundColor={entity.mobileBackgroundColor}
        fontSize={entity.fontSize}
        mobileFontSize={entity.mobileFontSize}
        boxShadow={entity.boxShadow}
        mobileBoxShadow={entity.mobileBoxShadow}
        color={entity.color}
        mobileColor={entity.mobileColor}
        value={value}
      />
      <FieldErrors errors={contactUsFieldError} align="center" />
    </StyleWrapper>
  )
}

export default ContactUsField
