import styled, { css } from 'styled-components'
import { addOpacityToRGBA } from 'common/utils/colorUtils'

interface TimeSlotButtonUiProps {
  selectedBackgroundColor: string
  isSelected: boolean
}

const TIME_SLOT_OPACITY = 0.1

export const TimeSlotButtonUi = styled.button<TimeSlotButtonUiProps>`
  padding: 13px 55px;
  display: flex;
  justify-content: center;
  align-items: center;

  outline: none;
  border: 1px solid #B5C3D580;
  border-radius: 10px;
  background-color: #fff;
  cursor: pointer;
  ${p => p.isSelected && css`
    color: ${p.selectedBackgroundColor};
    background-color: ${addOpacityToRGBA(p.selectedBackgroundColor, TIME_SLOT_OPACITY)};
    border-color: ${p.selectedBackgroundColor};
  `}
`
