import styled, { css } from 'styled-components'

export const EventInfoWrapperUi = styled.div<{ titlesColor?: string }>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 250px;

  ${p => p.color && css`
    & p {
      color: ${p.color}
    }
  `}

  ${p => p.titlesColor && css`
    & span {
      color: ${p.titlesColor};
    }
  `}
`
