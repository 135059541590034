import { backgroundColorCss, colorCss } from 'common/utils/styleUtilsNew'
import styled, { css } from 'styled-components'

interface CalendarDayButtonUiProps {
  isAvailable: boolean
  color: string
  backgroundColor: string
}

export const CalendarDayButtonUi = styled.button<CalendarDayButtonUiProps>`
  width: 100%;
  outline: none;
  border: none;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  font-size: inherit;

  padding: 3px 5px;
  border: 2px solid #fff;
  border-left: 5px solid #fff;
  border-right: 5px solid #fff;

  cursor: ${p => (p.isAvailable ? 'pointer' : 'default')};
  ${backgroundColorCss}
  ${colorCss}

  ${p => p.theme.isEditorMobileMode && css`
    height: 80%;
  `}
  ${p => p.theme.phone} {
    height: 80%;
  }
`
