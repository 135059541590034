import React from 'react'
import { DayProps } from 'react-day-picker'
import { CalendarDayButtonUi } from './ui/calendar-day-button-ui'
import { isDatesEqual } from './utils'

const defaultDateColors = {
  selected: '#fff',
  outside: '#B8B8B8',
}

interface CalendarDaySlotProps extends DayProps {
  onDateSelect?: (date: Date) => void
  availableSlotBackgroundColor: string
  selectedSlotBackgroundColor: string
  selectedDate: Date | undefined
  isLoading?: boolean
}

export function CalendarDaySlot({
  day: { date },
  modifiers,
  onDateSelect,
  selectedDate,
  availableSlotBackgroundColor,
  selectedSlotBackgroundColor,
  isLoading,
  ...props
}: CalendarDaySlotProps) {
  const { isDateAvailable, outside } = modifiers

  const isSelected = selectedDate && isDatesEqual(selectedDate, date)

  const isSlotAvailable = !outside && isDateAvailable

  const backgroundColor = isSelected
    ? selectedSlotBackgroundColor
    : isSlotAvailable
    ? availableSlotBackgroundColor
    : 'inherit'
  const dateColor = isSelected
    ? defaultDateColors.selected
    : outside
    ? defaultDateColors.outside
    : 'inherit'

  const onSelect = () => {
    if (isSlotAvailable && onDateSelect) {
      onDateSelect(date)
    }
  }

  return (
    <td {...props}>
      <CalendarDayButtonUi
        onClick={onSelect}
        backgroundColor={backgroundColor}
        color={dateColor}
        isAvailable={isSlotAvailable}
        type="button"
      >
        {date.getDate()}
      </CalendarDayButtonUi>
    </td>
  )
}
