import React from 'react'
import { WeekdayProps } from 'react-day-picker'
import { useTranslation } from 'react-i18next'
import styled, { css } from 'styled-components'
import { getReadableShortWeekdayName } from '../constants'

export function WeekdayUi({ children, ...props }: WeekdayProps) {
  // const { t } = useTranslation()

  return (
    <TableCellElementUi ref={props.ref as any} {...props}>
      {children}
      {/* {t(getReadableShortWeekdayName(Number(props['aria-label'])))} */}
    </TableCellElementUi>
  )
}

const TableCellElementUi = styled.th`
  position: relative;
  &::before {
    content: '';
    display: block;

    position: absolute;
    top: -2px;
    bottom: -2px;
    right: -2px;
    left: -2px;
    z-index: -1;

    background-color: #f8fafc;
  }

  ${p =>
    p.theme.isEditorMobileMode &&
    css`
      &::before {
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
      }
    `}
`
