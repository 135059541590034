import React from 'react'
import 'react-day-picker/style.css'
import { PaddingType } from 'common/types/styleTypes'
import { FontProps } from 'common/utils/styleUtilsNew'
import {
  AvailabilitySlot,
  EventInterface,
  EventLocationInterface,
} from './calendar-interface'
import { DayPicker } from './day-picker'
import { EventInfo } from './event-info'
import { TimeSlots } from './time-slots'
import { CommonCalendarContainerUi } from './ui/common-calendar-container-ui'
import { CommonCalendarUi } from './ui/common-calendar-ui'

interface CommonBookingCalendarProps {
  availabilitySlots: AvailabilitySlot[]

  availableSlotBackgroundColor: string
  selectedSlotBackgroundColor: string
  titlesColor?: string
  mobileTitlesColor?: string
  padding: PaddingType
  mobilePadding: PaddingType

  selectedDate?: Date
  onDateSelect?: (date: Date) => void
  selectedTimeSlot?: number
  onTimeSlotSelect?: (timeSlot: number) => void
  selectedTimeZone: string
  onTimeZoneSelect?: (timeZone: string) => void
  selectedMonth?: Date
  onMonthSelect?: (month: Date) => void
  selectedLocationId?: EventLocationInterface['id']
  onLocationSelect?: (locationId: EventLocationInterface['id']) => void

  event?: EventInterface
  isLoading?: boolean
}

function CommonBookingCalendar({
  availabilitySlots,
  selectedDate,
  selectedMonth,
  onMonthSelect,
  onDateSelect,
  selectedTimeSlot,
  onTimeSlotSelect,
  selectedTimeZone,
  onTimeZoneSelect,
  selectedLocationId,
  onLocationSelect,
  event,
  isLoading,

  availableSlotBackgroundColor,
  selectedSlotBackgroundColor,
  titlesColor,
  mobileTitlesColor,
  color,
  mobileColor,

  ...stylesProps
}: CommonBookingCalendarProps & FontProps) {
  return (
    <CommonCalendarContainerUi>
      <CommonCalendarUi {...stylesProps}>
        <EventInfo
          {...event}
          selectedTimeZone={selectedTimeZone}
          onTimeZoneSelect={onTimeZoneSelect}
          titlesColor={titlesColor}
          mobileTitlesColor={mobileTitlesColor}
          color={color}
          mobileColor={mobileColor}
          selectedSlotBackgroundColor={selectedSlotBackgroundColor}
          selectedLocationId={selectedLocationId}
          onLocationSelect={onLocationSelect}
          isLoading={isLoading}
        />
        <DayPicker
          selectedDate={selectedDate}
          onDateSelect={onDateSelect}
          selectedMonth={selectedMonth}
          onMonthSelect={onMonthSelect}
          isLoading={isLoading}
          availableSlotBackgroundColor={availableSlotBackgroundColor}
          selectedSlotBackgroundColor={selectedSlotBackgroundColor}
          color={color}
          mobileColor={mobileColor}
          titlesColor={titlesColor}
          mobileTitlesColor={mobileTitlesColor}
          availabilitySlots={availabilitySlots}
        />
        {selectedDate && (
          <TimeSlots
            availabilitySlots={availabilitySlots}
            onTimeSlotSelect={onTimeSlotSelect}
            selectedTimeSlot={selectedTimeSlot}
            selectedSlotBackgroundColor={selectedSlotBackgroundColor}
            selectedDate={selectedDate}
          />
        )}
      </CommonCalendarUi>
    </CommonCalendarContainerUi>
  )
}

export default CommonBookingCalendar
