import { useEffect, useState } from 'react'
import { EventInterface } from 'common/components/entities/Calendar/calendar-interface'
import { getEvent as getClientEvent } from 'client/api/booking-calendar-api'
import { getEvent as getPublisherEvent } from 'publisher/api/boookingCalendarApi'

interface UseEventArgs {
  scope: 'client' | 'publisher'
  eventId?: EventInterface['id']
}

export const useEvent = ({ scope, eventId }: UseEventArgs) => {
  const [event, setEvent] = useState<EventInterface>()
  const [error, setError] = useState<unknown>(null)
  const [isFetching, setIsFetching] = useState(false)

  const getEvent = scope === 'client' ? getClientEvent : getPublisherEvent

  const fetch = async (eventId: EventInterface['id']) => {
    setIsFetching(true)
    try {
      setIsFetching(true)
      const data = await getEvent(eventId)
      setEvent(data.data)
    } catch (error) {
      setError(error)
    } finally {
      setIsFetching(false)
    }
  }

  useEffect(() => {
    if(eventId) fetch(eventId)
  }, [eventId])

  return { event, isFetching, error, fetch }
}
