import { useState, useEffect } from 'react'
import { AvailabilitySlot } from 'common/components/entities/Calendar/calendar-interface'
import { getStartEndDates } from 'common/components/entities/Calendar/utils'
import { getTimeSlots } from 'publisher/api/boookingCalendarApi'
import { getBookingFieldValue } from 'publisher/reducers/optInReducer'
import { useOptIn } from 'publisher/store'
import { GetTimeSlotsArgs } from '../api/boookingCalendarApi'

export const useEventTimeSlots = () => {
  const eventId = useOptIn(optIn => getBookingFieldValue(optIn, 'eventId'))
  const ownerId = useOptIn(optIn => getBookingFieldValue(optIn, 'ownerId'))
  const startDate = useOptIn(optIn => getBookingFieldValue(optIn, 'startDate'))
  const endDate = useOptIn(optIn => getBookingFieldValue(optIn, 'endDate'))
  const isOptInSucceeded = useOptIn(optIn => optIn.success)
  const timeZone =
    useOptIn(optIn => getBookingFieldValue(optIn, 'timeZone')) ||
    Intl.DateTimeFormat().resolvedOptions().timeZone

  const [timeSlots, setTimeSlots] = useState<AvailabilitySlot[]>()
  const [error, setError] = useState<unknown>(null)
  const [isFetching, setIsFetching] = useState(false)

  const fetch = async (args: GetTimeSlotsArgs) => {
    try {
      setIsFetching(true)
      const data = await getTimeSlots(args)
      setTimeSlots(data.data)
    } catch (error) {
      setError(error)
    } finally {
      setIsFetching(false)
    }
  }

  useEffect(() => {
    if (eventId) {
      const timezone = timeZone
      const defaultMonth = getStartEndDates(new Date())
      fetch({
        eventId,
        startDate: startDate || defaultMonth.startDate,
        endDate: endDate || defaultMonth.endDate,
        timezone,
        ownerId,
      })
    }
  }, [eventId, timeZone, startDate, endDate, ownerId, isOptInSucceeded])

  return { timeSlots, isFetching, error, fetch }
}
