import axios from 'axios'
import {
  BookingFields,
  SurveysResults,
} from '../store/optIn/optInStateInterface'

interface OptInInterface {
  fields: Record<string, any>
  datetime: string
  timeZone: string
  popupId: string
  isDesktop: boolean
  surveysResults: SurveysResults
  entityId: string
  checkBoxIds: string[]
  captcha?: string
  booking?: BookingFields
}

export const optIn = (
  fields: Record<string, any>,
  datetime: string,
  timeZone: string,
  popupId: string,
  isDesktop: boolean,
  surveysResults: SurveysResults,
  entityId: string,
  checkBoxIds: string[],
  recaptchaToken?: string,
  booking?: BookingFields,
) => {
  const optin: OptInInterface = {
    fields,
    datetime,
    timeZone,
    popupId,
    isDesktop,
    surveysResults,
    entityId,
    captcha: recaptchaToken,
    checkBoxIds,
  }
  if (booking) optin.booking = { ...booking }

  return axios.post(location.href, {
    optin,
  })
}
