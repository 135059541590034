import React from 'react'
import { Skeleton } from '../ui/skeleton'

export const EventInfoSkeleton = () => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
    >
      <div style={{ display: 'flex', flexDirection: 'column', gap: 15 }}>
        <Skeleton width={250} height={40} />
        <Skeleton width={250} height={40} />
        <Skeleton width={250} height={40} />
        <Skeleton width={250} height={40} />
      </div>
      <div style={{ display: 'flex' }}>
        <Skeleton width={250} height={40} />
      </div>
    </div>
  )
}
