export const widthKeys = {
  fullPage: 'fullPage',
  wide: 'wide',
  medium: 'medium',
  small: 'small',
  extraSmall: 'extraSmall',
}

export const widthNames = {
  fullPage: 'settings_styles.content_width.full_page',
  wide: 'settings_styles.content_width.wide',
  medium: 'settings_styles.content_width.medium',
  small: 'settings_styles.content_width.small',
  extraSmall: 'settings_styles.content_width.extra_small',
}

export const widthValues = {
  fullPage: '100%',
  wide: '1120px',
  medium: '960px',
  small: '720px',
  extraSmall: '550px',
}

export const backgroundPositionKeys = {
  cover: 'cover',
  cover100Width: 'cover100Width',
  cover100WidthHeight: 'cover100WidthHeight',
  noRepeat: 'noRepeat',
  repeat: 'repeat',
  repeatX: 'repeatX',
  repeatY: 'repeatY',
  repeatXTop: 'repeatXTop',
  repeatXBottom: 'repeatXBottom',
  center: 'center',
}

const {
  cover,
  cover100Width,
  cover100WidthHeight,
  noRepeat,
  repeat,
  repeatX,
  repeatY,
  repeatXTop,
  repeatXBottom,
  center,
} = backgroundPositionKeys

export const backgroundPositionNames = {
  [cover]: 'Full Center (Fixed)',
  [cover100Width]: 'Fill 100% Width',
  [cover100WidthHeight]: 'Fill 100% Width & Height',
  [noRepeat]: 'No Repeat',
  [repeat]: 'Repeat',
  [repeatX]: 'Repeat Horizontally',
  [repeatY]: 'Repeat Vertically',
  [repeatXTop]: 'Repeat Horizontally - Top',
  [repeatXBottom]: 'Repeat Horizontally - Bottom',
  [center]: 'Center',
}

export const backgroundPositionValues = {
  [cover]: {
    backgroundSize: 'cover',
    backgroundAttachment: 'fixed',
    backgroundPosition: 'center center',
    backgroundRepeat: 'initial',
  } /* absolute position collapses section */,
  [cover100Width]: {
    backgroundSize: '100%',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'initial',
    backgroundAttachment: 'initial',
  },
  [cover100WidthHeight]: {
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
    backgroundRepeat: 'initial',
    backgroundAttachment: 'initial',
  },
  [noRepeat]: {
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'initial',
    backgroundSize: 'initial',
    backgroundAttachment: 'initial',
  },
  [repeat]: {
    backgroundRepeat: 'repeat',
    backgroundPosition: 'initial',
    backgroundSize: 'initial',
    backgroundAttachment: 'initial',
  },
  [repeatX]: {
    backgroundRepeat: 'repeat-x',
    backgroundPosition: 'initial',
    backgroundSize: 'initial',
    backgroundAttachment: 'initial',
  },
  [repeatY]: {
    backgroundRepeat: 'repeat-y',
    backgroundPosition: 'initial',
    backgroundSize: 'initial',
    backgroundAttachment: 'initial',
  },
  [repeatXTop]: {
    backgroundPosition: '50% 0',
    backgroundSize: 'initial',
    backgroundRepeat: 'initial',
    backgroundAttachment: 'initial',
  },
  [repeatXBottom]: {
    backgroundPosition: '50% 100%',
    backgroundSize: 'initial',
    backgroundRepeat: 'initial',
    backgroundAttachment: 'initial',
  },
  [center]: {
    backgroundPosition: '50% 50%',
    backgroundSize: 'initial',
    backgroundRepeat: 'initial',
    backgroundAttachment: 'initial',
  },
}

export const defaultTextFontSize = '18px'
export const defaultTextHeadlineFontSize = '24px'

export const buttonActionsTypes = {
  sendForm: 'sendForm',
  showPopup: 'showPopup',
  openUrl: 'openUrl',
  nextStepRedirection: 'nextStepRedirection',
  blogLink: 'blogLink',
  login: 'login',
  resetPassword: 'resetPassword',
  confirmRegistration: 'confirmRegistration',
  downloadFile: 'downloadFile',
}

export const buttonActionsNames = {
  sendForm: 'components.button.actions.send_form',
  showPopup: 'components.button.actions.show_popup',
  openUrl: 'components.button.actions.open_url',
  nextStepRedirection: 'components.button.actions.next_step_redirection',
  lectureCompleteAndRedirect:
    'components.button.actions.lecture_complete_and_redirect',
  blogLink: 'components.button.actions.blog_link',
  login: 'entity_settings.member_access.modes.choices.login',
  resetPassword: 'entities.member_access.request.default_button_text',
  confirmRegistration: 'entities.member_access.default_password_confirm_label',
  downloadFile: 'components.button.actions.download_file',
}

export const imageActionsKeys = {
  showPopup: 'showPopup',
  openUrl: 'openUrl',
}

export const imageActionsNames = {
  showPopup: 'components.image.actions.show_popup',
  openUrl: 'components.image.actions.open_url',
}

export const borderTypeKeys = {
  none: 'None',
  fullBorder: 'fullBorder',
  bottomOnly: 'bottomOnly',
  topOnly: 'topOnly',
  topAndBottom: 'topAndBottom',
}

const { none, fullBorder, bottomOnly, topOnly, topAndBottom } = borderTypeKeys

export const borderTypeNames = {
  [fullBorder]: 'Full Border',
  [bottomOnly]: 'Bottom Only',
  [topOnly]: 'Top Only',
  [topAndBottom]: 'Top & Bottom',
}

export const borderTypeValues = {
  [none]: { borderStyle: 'none' },
  [fullBorder]: {},
  [bottomOnly]: {
    borderTopWidth: '0',
    borderLeftWidth: '0',
    borderRightWidth: '0',
  },
  [topOnly]: {
    borderBottomWidth: '0',
    borderLeftWidth: '0',
    borderRightWidth: '0',
  },
  [topAndBottom]: { borderLeftWidth: '0', borderRightWidth: '0' },
}

export const borderStyleValues = {
  none: 'none',
  solid: 'solid',
  dashed: 'dashed',
  dotted: 'dotted',
}

export const borderSizes = {
  '1px': '1px',
  '2px': '2px',
  '3px': '3px',
  '5px': '5px',
  '10px': '10px',
}

export const defaultVideoStyles = {
  position: 'absolute',
  right: 0,
  bottom: 0,
  height: 'auto',
  width: 'auto',
  zIndex: '-10',
  minHeight: '100%',
  minWidth: '100%',
  objectFit: 'cover',
}
