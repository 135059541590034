import styled, { css } from 'styled-components'

export const TimeSlotListUi = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 20px;
  max-height: 450px;
  height: 100%;
  overflow-y: auto;

  ${p => p.theme.isEditorMobileMode && css`
    max-height: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  `}
  ${p => p.theme.phone} {
    max-height: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
`
