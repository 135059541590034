import React from 'react'
import { useDispatch } from 'react-redux'
import CommonImage from 'common/components/entities/ImageNew'
import { PageTypeEnum } from 'common/enums/PageTypeEnum'
import { ImageInterface } from 'common/types/entities/ImageInterface'
import { showPopup } from 'publisher/actionsManagement'
import StyleWrapper from 'publisher/components/core/StyleWrapper'
import { useDelay } from '../../hooks/useDelay'
import usePage from '../../hooks/usePage'
import { useFiles } from '../../store'
import { fileSelectors } from '../../store/file/fileSelectors'
import pageSelectors from '../../store/page/pageSelectors'

function renderImage(
  entity: ImageInterface,
  src: string,
  loading: 'lazy' | 'eager',
  pointer = false,
  onClick?: (e: React.SyntheticEvent) => void,
) {
  return (
    <CommonImage
      attrId={entity.htmlAttrId}
      src={src}
      alt={entity.alt}
      alignSelf={entity.alignSelf}
      mobileAlignSelf={entity.mobileAlignSelf}
      boxShadow={entity.boxShadow}
      mobileBoxShadow={entity.mobileBoxShadow}
      border={entity.border}
      mobileBorder={entity.mobileBorder}
      background={entity.background}
      mobileBackground={entity.mobileBackground}
      delay={entity.delay}
      blur={entity.blur}
      ratio={entity.ratio}
      width={entity.width}
      mobileWidth={entity.mobileWidth}
      onClick={onClick}
      pointer={pointer}
      loading={loading}
      link={entity.link}
      openNewTab={entity.openNewTab}
    />
  )
}

function Image({ entity }: { entity: ImageInterface }) {
  const dispatch = useDispatch()
  const isVisible = useDelay(entity.delay)
  const pageType = usePage(pageSelectors.getPageType)
  const file = useFiles(files => fileSelectors.getFile(files, entity.fileId))
  const loading =
    pageType === PageTypeEnum.Popup || pageType === PageTypeEnum.Inline
      ? 'eager'
      : 'lazy'

  let imageSrc
  if (file) {
    imageSrc = file.path
  } else if (entity.externalUrl) {
    imageSrc = entity.externalUrl
  } else {
    return <div />
  }

  let image
  if (entity.popupId) {
    image = renderImage(entity, imageSrc, loading, true, () =>
      dispatch(showPopup(entity.popupId)),
    )
  } else {
    image = renderImage(entity, imageSrc, loading)
  }

  return isVisible ? (
    <StyleWrapper
      margin={entity.margin}
      mobileMargin={entity.mobileMargin}
      appearance={entity.appearance}
      flex
      flexColumn
    >
      {image}
    </StyleWrapper>
  ) : (
    <div />
  )
}

export default Image
