import styled, { css } from 'styled-components'

export const TimeSlotsWrapperUi = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;

  ${p => p.theme.isEditorMobileMode && css`
    max-width: 100%;
    margin-top: 40px;
  `}
  ${p => p.theme.phone} {
    max-width: 100%;
    margin-top: 40px;
  }
`
